import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';
import { CigarStore } from '../../stores';

export const selectSmokeSessionMapping = () => (state) => {
  console.log('selectSmokeSessionMapping');
  console.log(state.getIn(['global', 'smokeSessions']).toJS());
  return state.getIn(['global', 'smokeSessions']);
};
export const selectCigarSmokeSessions = (cigarId) => createSelector(
  selectSmokeSessionMapping(),
  // FIXME Same issue as with Humidors
  // (sessionState) => sessionState.get(cigarId, fromJS({
  //   currentPage: 0,
  //   totalPages: 999,
  //   loading: false,
  //   errorMessage: '',
  //   sessions: List(),
  // }))
  (sessionState) => {
    const state = sessionState.toJS();
    const cigarState = state[cigarId];
    if (cigarState) {
      return fromJS(cigarState);
    }
    return fromJS({
      currentPage: 0,
      totalPages: 999,
      loading: false,
      errorMessage: '',
      sessions: List(),
    });
  }
);

export const selectCigarSmokeSessionsLoading = (cigarId) => createSelector(
  selectCigarSmokeSessions(cigarId),
  (sessionState) => sessionState.get('loading'),
);

export const selectCigarSmokeSessionsErrorMessage = (cigarId) => createSelector(
  selectCigarSmokeSessions(cigarId),
  (sessionState) => sessionState.get('errorMessage'),
);

export const selectCigarSmokeSessionsCurrentPage = (cigarId) => createSelector(
  selectCigarSmokeSessions(cigarId),
  (sessionState) => sessionState.get('currentPage'),
);

export const selectCigarSmokeSessionsTotalPage = (cigarId) => createSelector(
  selectCigarSmokeSessions(cigarId),
  (sessionState) => sessionState.get('totalPages'),
);

// FIXME This name is wrong
export const selectWpPostHasMore = (cigarId) => createSelector(
  selectCigarSmokeSessionsCurrentPage(cigarId),
  selectCigarSmokeSessionsTotalPage(cigarId),
  (currentPage, totalPage) => (currentPage < totalPage),
);

// export const selectAllCigarSmokeSessions = (cigarId, page) => createSelector(
//   selectCigarSmokeSessions(cigarId),
//   (sessionState) => {
//     // TODO Paging
//     console.log(sessionState);
//     return sessionState.get('sessions');
//   },
// );

// TODO How to handle paging?
// FIXME These are deprecated - I use Pullstate's useState directly in the components
export const selectAllCigarSmokeSessions = (cigarId, page) => CigarStore.getRawState().sessions[cigarId] || [];
export const selectAllUserSmokeSessions = (cigarId, userId, page) => {
  const sessions = selectAllCigarSmokeSessions(cigarId, page);
  return sessions.filter((session) => session.scan.user_id === userId);
};
