import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  LOAD_CIGAR_SESSIONS,
  LOAD_USER_SESSIONS,
  SAVE_SESSION,
  DELETE_SESSION,
  loadCigarSessions,
  loadCigarSessionsSuccess,
  loadCigarSessionsFail,
  loadUserSessions,
  loadUserSessionsSuccess,
  loadUserSessionsFail,
  saveSessionFail,
  saveSessionSuccess,
  savingUserSession,
  deletingUserSession,
  deleteSessionSuccess,
  deleteSessionFail,
} from './actions';
import { config } from '../../settings';

export function* cigarSessions(action) {
  const { cigarId, page, callback } = action;
  yield put(loadCigarSessions(cigarId, page));

  const paging = { totalPages: 999 }; // FIXME Pass from server?

  try {
    const response = yield axios.get(`${config.apiEndPoint}/cigars/${cigarId}/sessions`);
    console.log(response);
    yield put(loadCigarSessionsSuccess(cigarId, paging, response.data, callback));
  } catch (err) {
    console.log(err);
    yield put(loadCigarSessionsFail(cigarId, err));
  }
}

export function* cigarSessionsWatcher() {
  yield takeLatest(LOAD_CIGAR_SESSIONS.REQUEST, cigarSessions);
}

export function* userSessions(action) {
  const { userId, page, limit } = action;
  yield put(loadUserSessions(userId, page));

  const paging = { totalPages: 999 }; // FIXME Pass from server?

  try {
    const response = yield axios.get(`${config.apiEndPoint}/users/${userId}/sessions`);
    yield put(loadUserSessionsSuccess(userId, paging, response.data));
  } catch (err) {
    yield put(loadUserSessionsFail(userId, err));
  }
}

export function* userSessionsWatcher() {
  yield takeLatest(LOAD_USER_SESSIONS.REQUEST, userSessions);
}

export function* saveSession(action) {
  const { session } = action;
  yield put(savingUserSession(session));
  try {
    let response;
    if (session.id) {
      console.log('PUT Request');
      response = yield axios.put(`${config.apiEndPoint}/sessions/${session.id}`, session);
    } else {
      console.log('POST Request');
      response = yield axios.post(`${config.apiEndPoint}/sessions`, session);
    }
    console.log(response.data);
    if (response.data && response.data.id) {
      if (session.media_to_add && session.media_to_add.length > 0) {
        const mediaResponse = yield axios.post(`${config.apiEndPoint}/sessions/${response.data.id}/media`, session.media_to_add);
      }
      if (session.media_to_delete && session.media_to_delete.length > 0) {
        const mediaResponse = yield axios.delete(`${config.apiEndPoint}/sessions/${response.data.id}/media`, session.media_to_delete);
      }
    }
    // TODO Do we need to merge the media into the session response? Get the session from the server at this point?
    yield put(saveSessionSuccess(response.data));
  } catch (err) {
    console.error(err);
    yield put(saveSessionFail(session, err));
  }
}

export function* saveSessionWatcher() {
  yield takeLatest(SAVE_SESSION.REQUEST, saveSession);
}

export function* deleteSession(action) {
  const { session } = action;
  yield put(deletingUserSession(session));
  try {
    const response = yield axios.delete(`${config.apiEndPoint}/sessions/${session.id}`);
    console.log(response);
    yield put(deleteSessionSuccess(session));
  } catch (err) {
    console.log(err);
    yield put(deleteSessionFail(session, err));
  }
}

export function* deleteSessionWatcher() {
  yield takeLatest(DELETE_SESSION.REQUEST, deleteSession);
}

export default [
  cigarSessionsWatcher,
  userSessionsWatcher,
  saveSessionWatcher,
  deleteSessionWatcher,
];
