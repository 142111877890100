import { Button, Collapse } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import Icon from '../../components/Icon';
import PageWrap from '../../components/page-wrap';
import axios from 'axios';
import { config } from '../../settings';
import { Transducer } from '../../utils/transducer';

export const pushNotificationsTags = [{
  tag: 'social',
  label: 'Social',
  description: 'For social interactions, group posts, and other related updates',
}, {
  tag: 'shopping',
  label: 'Price Drops & Cart Reminders',
  description: 'When cigars you are interested in drop in price or items are left in your cart',
}, {
  tag: 'humidor',
  label: 'Humidor Inventory Alerts',
  description: 'When cigars in your humidor are running low, Boxpressd can alert you and let you know where to purchase more',
}, {
  tag: 'events',
  label: 'Upcoming Local Events',
  description: 'When events are coming up in your local area',
}, {
  tag: 'cigar_news',
  label: 'Daily Cigar News',
  description: 'Alerts for new articles and reviews from Boxpressd or our affiliates',
}];

let optedOut = false;

function NotificationSettings(props) {
  const { auth } = props;
  const user = auth ? auth.user : null;
  const [showSocialRequests, setShowSocialRequests] = useState(false);
  const [showCartReminders, setShowCartReminders] = useState(false);
  const [showLocalEvents, setShowLocalEvents] = useState(false);
  const [showHumidor, setShowHumidor] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [switchState, setSwitchState] = useState({
    social: ['Push', 'Email'],
    shopping: ['Push', 'Email'],
    events: ['Push', 'Email'],
    humidor: ['Push', 'Email'],
    news: ['Push', 'Email'],
  });

  const updateTagSwitches = (tags) => {
    if (tags) {
      Object.keys(tags).forEach((tag) => {
        const tagKeys = Object.keys(switchState);
        if (tagKeys.includes(tag)) {
          console.log('Adding tag to state...');
          const value = tags[tag];
          if (value === 'false') {
            switchState[tag].splice(switchState[tag].indexOf('Push'), 1);
            setSwitchState({ ...switchState });
          }
        }
      });
    }
  };

  useEffect(() => {
    try {
      // FIXME If not already registered only
      console.log('Registering for push notifications on OneSignal...');
      window.OneSignalPlugin.registerForPushNotifications();
    } catch(e) {
      Sentry.captureException(e);
    }
    try {
      (async () => {
        await window.OneSignalPlugin.getTags((tags) => {
          if (tags) {
            console.log('OneSignal tags:');
            console.log(tags);
            updateTagSwitches(tags);
          }
        });
      })();
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [window.OneSignalPlugin, window.OneSignal]);

  useEffect(() => {
    checkPushStatus();
    if (Transducer.isNative()) {
      const tags = window.localStorage.getItem('boxpressd_onesignal_tags');
      if (tags) {
        updateTagSwitches(JSON.parse(tags));
      }
    }
    try {
      // FIXME This doesn't seem part of the React library - is there an alternative?
      if (typeof window.OneSignalPlugin.isOptedOut === 'function') {
        window.OneSignalPlugin.isOptedOut().then((isOptedOut) => {
          optedOut = isOptedOut;
        });
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }, []);

  useEffect(() => {
    if (user && user.id) {
      (async () => {
        // TODO Consider having this function only return blocked email topics. That's really all we need here
        await axios.get(`${config.apiEndPoint}/users/${user.id}/notifications/email`).then((res) => {
          function getArrayDiff(a1, a2) {
            return a1.filter((v) => a2.indexOf(v) < 0).concat(a2.filter((v) => a1.indexOf(v) < 0));
          }
          const emailTags = res.data;
          const blockedTags = getArrayDiff(emailTags, Object.keys(switchState));
          blockedTags.forEach((tag) => {
            const tagKeys = Object.keys(switchState);
            if (tagKeys.includes(tag)) {
              console.log('Adding tag to state...');
              if (switchState[tag].indexOf('Email') !== -1) {
                switchState[tag].splice(switchState[tag].indexOf('Email'), 1);
                setSwitchState({ ...switchState });
              }
            }
          });
        });
      })();
    }
  }, [user]);

  const toggleCollapse = (type) => {
    setShowSocialRequests((show) => type === 'social' && !show);
    setShowCartReminders((show) => type === 'shopping' && !show);
    setShowLocalEvents((show) => type === 'events' && !show);
    setShowHumidor((show) => type === 'humidor' && !show);
    setShowNews((show) => type === 'news' && !show);
  };

  const notifications = [{
    title: 'Social',
    type: 'social',
    description: '',
    onClick: () => {
      toggleCollapse('social');
    },
    isOpen: showSocialRequests,
  }, {
    title: 'Price Drops & Cart Reminders',
    type: 'shopping',
    description: '',
    onClick: () => {
      toggleCollapse('shopping');
    },
    isOpen: showCartReminders,
  }, {
    title: 'Upcoming Local Events',
    type: 'events',
    description: '',
    onClick: () => {
      toggleCollapse('events');
    },
    isOpen: showLocalEvents,
  }, {
    title: 'Humidor Inventory Alerts',
    type: 'humidor',
    description: '',
    onClick: () => {
      toggleCollapse('humidor');
    },
    isOpen: showHumidor,
  }, {
    title: 'Daily Cigar News',
    type: 'news',
    description: '',
    onClick: () => {
      toggleCollapse('news');
    },
    isOpen: showNews,
  }];

  const checkPushStatus = () => {
    if (typeof window.OneSignalPlugin.getNotificationPermission === 'function') {
      window.OneSignalPlugin.getNotificationPermission((permission) => {
        if (permission === 'default') {
          window.OneSignalPlugin.showNativePrompt();
        }
      });
    }
  };

  // function isPushNotificationsEnabledVerbose() {
  //   console.log('isPushNotificationsEnabledVerbose()');
  //   Promise.all([
  //     OneSignal.isPushNotificationsEnabled(),
  //     OneSignal.getUserId(),
  //     OneSignal.getRegistrationId(),
  //     OneSignal.getNotificationPermission(),
  //     OneSignal.isOptedOut(),
  //     OneSignal.context.serviceWorkerManager.getActiveState()
  //   ])
  //     .then(([isSubscribed, userId, registrationId, notificationPermission, optedOut, serviceWorkerActive]) => {
  //       console.log('Is Completely Subscribed:', isSubscribed);
  //       console.log('');
  //       console.log('What is our OneSignal user ID?', userId);
  //       console.log('What is our push subscription token?', registrationId);
  //       console.log('What is the notification permission status?', notificationPermission);
  //       console.log('Are you manually opted out?', optedOut);
  //       console.log("Is a service worker registered and active? (should be false on Safari, otherwise should be 'Worker A (Main)')?", serviceWorkerActive);
  //       console.log('What is the current URL of this page?', location.href);
  //       console.log("What environment does OneSignal think it's in?", OneSignal.sdkEnvironment.getWindowEnv());
  //     })
  //     .catch(e => {
  //       console.error("Issue determining whether push is enabled:", e);
  //     });
  // }
  //
  // const testPushNotifications = () => {
  //   try {
  //     isPushNotificationsEnabledVerbose();
  //     window.OneSignal.sendSelfNotification();
  //   } catch (e) {
  //     console.error(e);
  //     alert('There was a problem creating the test notification. Please contact support if the problem persists.');
  //     alert(e.message);
  //     Sentry.captureException(e);
  //   }
  // };

  const updateSwitchState = (type, method, checked) => {
    console.log(type);
    console.log(switchState[type]);
    const types = [...switchState[type]];
    if (checked) {
      types.push(method);
    } else {
      types.splice(types.indexOf(method), 1);
    }
    const state = { ...switchState, [type]: types };
    setSwitchState(state);
    if (method === 'Email') {
      const allowed = [];
      Object.keys(state).forEach((key) => {
        if (state[key].indexOf('Email') !== -1) {
          allowed.push(key);
        }
      });
      axios.post(`${config.apiEndPoint}/users/${user.id}/notifications/email`, allowed);
    }
    // TODO Update in local storage so it can be restored?
  };
  return (
    <PageWrap>
      <div style={{ width: '100%', maxWidth: 630, margin: '12px auto' }}>
        {/*<div style={{ textAlign: 'center' }}>*/}
        {/*  <Button color="primary" onClick={testPushNotifications}>Test Push Notifications</Button>*/}
        {/*</div>*/}
        <h3 style={{ margin: '8px 16px' }}>Manage Notification Preferences</h3>
        <List>
          {notifications.map((notification) => (
            <>
              <ListItem
                onClick={notification.onClick}
              >
                <ListItemText primary={notification.title} secondary={switchState[notification.type].join(', ')} />
                <ListItemSecondaryAction onClick={notification.onClick}>
                  <Icon name="chevron-down" style={{ height: 18, width: 18, marginLeft: 10 }} />
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse isOpen={notification.isOpen} className="navbar-collapse rui-navbar-collapse">
                <List>
                  <ListItem>
                    <ListItemText primary="Push" />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        onChange={(event) => {
                          updateSwitchState(notification.type, 'Push', event.target.checked);
                          try {
                            window.OneSignalPlugin.sendTags({ [notification.type]: event.target.checked ? 'true' : 'false' });
                            Transducer.postMessage('notification_preference_updated', {
                              tags: { [notification.type]: event.target.checked ? 'true' : 'false' },
                              checked: event.target.checked,
                            });
                          } catch (e) {
                            Sentry.captureException(e);
                          }
                          if (optedOut) {
                            // Opt them back in
                            window.OneSignalPlugin.setSubscription(true);
                          } else {
                            try {
                              if (typeof window.OneSignalPlugin.registerForPushNotifications === 'function') {
                                window.OneSignalPlugin.registerForPushNotifications();
                              }
                            } catch (e) {
                              console.error(e);
                            }
                          }
                        }}
                        checked={switchState[notification.type].indexOf('Push') !== -1}
                        inputProps={{ 'aria-labelledby': 'switch-list-label-enable-email-notifications' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Email" />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        onChange={(event) => {
                          // props.updateUserSetting('allow_email_notifications', event.target.checked);
                          updateSwitchState(notification.type, 'Email', event.target.checked);
                        }}
                        checked={switchState[notification.type].indexOf('Email') !== -1}
                        inputProps={{ 'aria-labelledby': 'switch-list-label-enable-email-notifications' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {/*<ListItem*/}
                  {/*  onClick={() => {*/}

                  {/*  }}*/}
                  {/*>*/}
                  {/*  <ListItemText primary="SMS" />*/}
                  {/*  <ListItemSecondaryAction>*/}
                  {/*    <Switch*/}
                  {/*      edge="end"*/}
                  {/*      onChange={(event) => {*/}
                  {/*        // if !already set*/}
                  {/*        // OneSignal.setSMSNumber("+11234567890");*/}
                  {/*        updateSwitchState(notification.type, 'SMS', event.target.checked);*/}
                  {/*      }}*/}
                  {/*      checked={switchState[notification.type].indexOf('SMS') !== -1}*/}
                  {/*      inputProps={{ 'aria-labelledby': 'switch-list-label-enable-email-notifications' }}*/}
                  {/*    />*/}
                  {/*  </ListItemSecondaryAction>*/}
                  {/*</ListItem>*/}
                </List>
              </Collapse>
            </>
          ))}
        </List>
      </div>
    </PageWrap>
  );
}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

export default connect(mapStateToProps)(NotificationSettings);
